/* HomePage.css */
body {
  width: 100%;
}

/* To hide the scrollbar on webkit browsers (e.g., Chrome) */
body::-webkit-scrollbar {
  width: 0;
  height: 0;
}

/* To hide the scrollbar track on webkit browsers (e.g., Chrome) */
body::-webkit-scrollbar-track {
  background: transparent;
}

/* To hide the scrollbar thumb (the draggable part) on webkit browsers (e.g., Chrome) */
body::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Allow scrolling by adding a custom scrollbar on webkit browsers (e.g., Chrome) */
body::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
